import './footer.css';

export const Footer = () => {
    return (
        <div className='footer-root'>
            <div></div>
            <a><b>Copyright</b> Bapenda Kab. Malang</a>
        </div>

    )
}